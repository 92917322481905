
import { stateType } from "./navigationDataType";
import { defineComponent, reactive, toRefs } from "vue";

const states: stateType = {
  back: false,
  leftText: "",
  title: "登录",
  rightText: "",
};

/**
 * 操作的hooks
 */
const useOperation = (state: stateType, context: any) => {
  const onClickLeft = () => {
    context.emit("onLeft");
  };
  const onClickRight = () => {
    context.emit("onRight");
  };
  return {
    onClickLeft,
    onClickRight,
  };
};

export default defineComponent({
  setup(prop, context) {
    const state = reactive<stateType>(states);
    const { onClickLeft, onClickRight } = useOperation(state, context);
    return {
      ...toRefs(state),
      onClickLeft,
      onClickRight,
    };
  },
});
