
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { stateType } from "./loginDataType";
import navigation from "@/components/navigation/navigation.vue";
import { userLogin, bindPhoneNumber } from "../../../api/user";
import { useRouter, Router } from "vue-router";
import { Notify } from "vant";
import Cookies from 'js-cookie'

const states: stateType = {
  tel: "",
  sms: "",
  time: 60,
};

// 事件相关hooks
const useEvent = (state: stateType, router: Router) => {
  const { login } = useNetwork(state);
  const sendCode = () => {
    if (!state.tel) {
      Notify({ type: "danger", message: "请先输入手机号" });
      return;
    }
    login();
  };
  const onLogin = async () => {
    if (!state.tel) {
      Notify({ type: "danger", message: "请先输入手机号" });
      return;
    }
    if (!state.sms) {
      Notify({ type: "danger", message: "请先输入验证码" });
      return;
    }
    const result: any = await bindPhoneNumber({
      msgCode: state.sms,
      phoneNumber: state.tel,
    });
    if (result.data.code == 0) {
      Notify({ type: "success", message: "已绑定" });
      Cookies.set('isBind', true)
      router.replace("/");
    }
  };
  return {
    sendCode,
    onLogin,
  };
};

// 接口相关hooks
const useNetwork = (state: stateType) => {
  const login = async () => {
    const result: any = await userLogin({ phoneNumber: state.tel });
    if (result.data.code == 0) {
      Notify({ type: "success", message: "已发送验证码" });
      const timer = setInterval(() => {
        --state.time;
        if (state.time <= 0) {
          clearInterval(timer);
          state.time = 60;
        }
      }, 1000);
    }
  };
  return {
    login,
  };
};

export default defineComponent({
  components: {
    navigation,
  },
  setup() {
    const router = useRouter();
    const state = reactive<stateType>(states);
    const { sendCode, onLogin } = useEvent(state, router);
    onMounted(() => {
      Notify("请先绑定手机号");
    });
    return {
      ...toRefs(state),
      sendCode,
      onLogin,
    };
  },
});
