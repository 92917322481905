import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    title: _ctx.title,
    "left-arrow": _ctx.back,
    "left-text": _ctx.leftText,
    "safe-area-inset-top": "",
    "right-text": _ctx.rightText,
    onClickLeft: _ctx.onClickLeft,
    onClickRight: _ctx.onClickRight
  }, null, 8, ["title", "left-arrow", "left-text", "right-text", "onClickLeft", "onClickRight"]))
}